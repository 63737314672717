<template>
  <section class="h-auto font-serif">
    <header
      class="pb-12 bg-white relative overflow-y-hidden pt-37  pl-16 pr-12"
    >
      <div
        class="bread-crumb flex text-transgray600 font-transsemibold text-stat leading-100"
      >
        States
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.57507 7L5.07088 4.49581C4.84307 4.26801 4.84307 3.89866 5.07088 3.67085C5.29868 3.44305 5.66803 3.44305 5.89584 3.67085L8.8125 6.58752C9.04031 6.81533 9.04031 7.18467 8.8125 7.41248L5.89584 10.3291C5.66803 10.557 5.29868 10.557 5.07088 10.3291C4.84307 10.1013 4.84307 9.73199 5.07088 9.50419L7.57507 7Z"
            fill="#383838"
          />
        </svg>
        <span class="text-transpurple">Lagos State</span>
      </div>
      <div class="flex items-center">
        <div class="text pt-5 md:pt-8 lg:pt-5 text-center md:text-left ">
          <h1
            class="font-bold leading-loose md:leading-58 text-transpurple text-2xl md:text-5xl"
          >
            Latest Corruption
          </h1>
          <h1
            class="font-bold leading-loose md:leading-58 text-transpurple text-2xl md:text-5xl"
          >
            stats about Lagos
          </h1>
          <p class="text-17 leading-28  text-transgray500 pt-5">
            Political corruption is a persistent phenomenon in Nigeria.
          </p>
          <p class="text-17 leading-28 text-transgray500">
            In 2012, Nigeria was estimated to 1944, a firm belonging to
          </p>
          <p class="text-17 leading-28 text-transgray500">
            Azikiwe and family bought a Bank in Lagos.
          </p>
        </div>
        <div class="stat flex  mx-auto border-t pt-16">
          <div class="left flex items-center cursor-pointer">
            <svg
              width="61"
              height="60"
              viewBox="0 0 61 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.4006 3.31514C33.8902 3.31514 37.3456 4.00247 40.5696 5.33788C43.7936 6.67329 46.723 8.63064 49.1905 11.0982C51.658 13.5657 53.6153 16.4951 54.9508 19.719C56.2862 22.943 56.9735 26.3984 56.9735 29.888C56.9735 33.3776 56.2862 36.8331 54.9508 40.057C53.6153 43.281 51.658 46.2104 49.1905 48.6779C46.723 51.1454 43.7936 53.1028 40.5696 54.4382C37.3456 55.7736 33.8902 56.4609 30.4006 56.4609C26.911 56.4609 23.4556 55.7736 20.2316 54.4382C17.0076 53.1028 14.0782 51.1454 11.6107 48.6779C9.1432 46.2104 7.18586 43.281 5.85045 40.057C4.51503 36.8331 3.82771 33.3776 3.82771 29.888C3.82771 26.3984 4.51504 22.943 5.85045 19.719C7.18586 16.4951 9.14321 13.5657 11.6107 11.0982C14.0783 8.63064 17.0076 6.67329 20.2316 5.33788C23.4556 4.00247 26.911 3.31514 30.4006 3.31514L30.4006 3.31514Z"
                stroke="#DBDFF1"
                stroke-width="3.18557"
              />
              <path
                d="M57.3036 33.1118C56.7427 38.0099 54.855 42.662 51.8443 46.5661C48.8336 50.4703 44.8142 53.4783 40.2195 55.2658C35.6247 57.0534 30.6292 57.5525 25.7716 56.7094C20.9141 55.8663 16.3789 53.7129 12.6553 50.4816"
                stroke="#FFBA69"
                stroke-width="5.30928"
                stroke-linecap="round"
              />
              <path
                d="M6.27151 42.3148C4.65837 39.1459 3.68518 35.6904 3.4075 32.1454C3.12983 28.6004 3.55311 25.0354 4.65318 21.654C5.75325 18.2726 7.50857 15.141 9.81891 12.438C12.1292 9.73494 14.9494 7.51344 18.1182 5.90029"
                stroke="#FF708B"
                stroke-width="5.30928"
                stroke-linecap="round"
              />
              <path
                d="M29.6599 2.96378C35.3881 2.8067 41.0185 4.47098 45.7408 7.7171C50.4631 10.9632 54.0338 15.6238 55.939 21.0282"
                stroke="#8676FF"
                stroke-width="5.30928"
                stroke-linecap="round"
              />
              <path
                d="M22.9067 42.6643C25.8457 42.6643 27.8955 40.6688 27.8955 37.9063C27.8955 35.232 26.0357 33.3044 23.5108 33.3044C22.3841 33.3044 21.3864 33.7524 20.8705 34.3632H20.7891L21.1895 30.6777H27.1692V28.5736H19.1058L18.3795 35.7411L20.6533 36.1144C21.1216 35.5985 22.004 35.2524 22.8389 35.2592C24.366 35.266 25.4656 36.3927 25.4588 37.9674C25.4656 39.5217 24.3932 40.628 22.9067 40.628C21.6511 40.628 20.6397 39.8339 20.5515 38.6936H18.108C18.1759 41.0013 20.1918 42.6643 22.9067 42.6643ZM35.4666 42.7389C38.8196 42.7457 40.8219 40.0986 40.8219 35.5375C40.8219 31.0035 38.8061 28.3835 35.4666 28.3835C32.1272 28.3835 30.1182 30.9967 30.1114 35.5375C30.1114 40.0918 32.1137 42.7389 35.4666 42.7389ZM35.4666 40.6145C33.7359 40.6145 32.6499 38.8769 32.6567 35.5375C32.6634 32.2252 33.7426 30.4808 35.4666 30.4808C37.1974 30.4808 38.2766 32.2252 38.2834 35.5375C38.2834 38.8769 37.2042 40.6145 35.4666 40.6145Z"
                fill="#070241"
              />
              <path
                d="M44.413 30.0954C44.413 30.6895 44.4777 31.1352 44.607 31.4322C44.7363 31.7293 44.946 31.8778 45.2361 31.8778C45.8093 31.8778 46.0959 31.2837 46.0959 30.0954C46.0959 28.9141 45.8093 28.3234 45.2361 28.3234C44.946 28.3234 44.7363 28.4702 44.607 28.7638C44.4777 29.0574 44.413 29.5012 44.413 30.0954ZM46.8141 30.0954C46.8141 30.8923 46.6796 31.4951 46.4104 31.9041C46.1448 32.3095 45.7534 32.5122 45.2361 32.5122C44.7468 32.5122 44.3659 32.3042 44.0932 31.8883C43.8241 31.4724 43.6896 30.8748 43.6896 30.0954C43.6896 29.302 43.8189 28.7044 44.0775 28.3025C44.3396 27.9005 44.7258 27.6996 45.2361 27.6996C45.7429 27.6996 46.1326 27.9075 46.4052 28.3234C46.6778 28.7393 46.8141 29.33 46.8141 30.0954ZM49.0265 33.1675C49.0265 33.7652 49.0911 34.2125 49.2204 34.5096C49.3497 34.8032 49.5612 34.95 49.8548 34.95C50.1483 34.95 50.365 34.8049 50.5048 34.5148C50.6446 34.2213 50.7145 33.7721 50.7145 33.1675C50.7145 32.5699 50.6446 32.1277 50.5048 31.8411C50.365 31.5511 50.1483 31.406 49.8548 31.406C49.5612 31.406 49.3497 31.5511 49.2204 31.8411C49.0911 32.1277 49.0265 32.5699 49.0265 33.1675ZM51.4328 33.1675C51.4328 33.9609 51.2982 34.562 51.0291 34.9709C50.7635 35.3764 50.372 35.5791 49.8548 35.5791C49.3585 35.5791 48.9758 35.3711 48.7067 34.9552C48.441 34.5393 48.3082 33.9434 48.3082 33.1675C48.3082 32.3741 48.4375 31.7765 48.6962 31.3746C48.9583 30.9726 49.3445 30.7717 49.8548 30.7717C50.3511 30.7717 50.7373 30.9779 51.0134 31.3903C51.293 31.7992 51.4328 32.3916 51.4328 33.1675ZM50.0802 27.8097L45.8285 35.4742H45.0579L49.3095 27.8097H50.0802Z"
                fill="#070241"
              />
              <path
                d="M25.7059 19.8587H27.7923V24.553H25.7059V19.8587ZM33.0081 17.7723H35.0945V24.553H33.0081V17.7723ZM29.357 14.6428H31.4434V24.553H29.357V14.6428Z"
                fill="#8676FF"
              />
            </svg>
            <div class="title ml-4 text-transpurple100">
              <p class="sector text-base leading-25 ">Private sector cases</p>
              <h1 class="text-21 font-transsemibold">194</h1>
            </div>
          </div>
          <div class="right ml-10 flex items-center cursor-pointer">
            <svg
              width="61"
              height="60"
              viewBox="0 0 61 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.4006 3.31514C33.8902 3.31514 37.3456 4.00247 40.5696 5.33788C43.7936 6.67329 46.723 8.63064 49.1905 11.0982C51.658 13.5657 53.6153 16.4951 54.9508 19.719C56.2862 22.943 56.9735 26.3984 56.9735 29.888C56.9735 33.3776 56.2862 36.8331 54.9508 40.057C53.6153 43.281 51.658 46.2104 49.1905 48.6779C46.723 51.1454 43.7936 53.1028 40.5696 54.4382C37.3456 55.7736 33.8902 56.4609 30.4006 56.4609C26.911 56.4609 23.4556 55.7736 20.2316 54.4382C17.0076 53.1028 14.0782 51.1454 11.6107 48.6779C9.1432 46.2104 7.18586 43.281 5.85045 40.057C4.51503 36.8331 3.82771 33.3776 3.82771 29.888C3.82771 26.3984 4.51504 22.943 5.85045 19.719C7.18586 16.4951 9.14321 13.5657 11.6107 11.0982C14.0783 8.63064 17.0076 6.67329 20.2316 5.33788C23.4556 4.00247 26.911 3.31514 30.4006 3.31514L30.4006 3.31514Z"
                stroke="#DBDFF1"
                stroke-width="3.18557"
              />
              <path
                d="M57.3036 33.1118C56.7427 38.0099 54.855 42.662 51.8443 46.5661C48.8336 50.4703 44.8142 53.4783 40.2195 55.2658C35.6247 57.0534 30.6292 57.5525 25.7716 56.7094C20.9141 55.8663 16.3789 53.7129 12.6553 50.4816"
                stroke="#FFBA69"
                stroke-width="5.30928"
                stroke-linecap="round"
              />
              <path
                d="M6.27151 42.3148C4.65837 39.1459 3.68518 35.6904 3.4075 32.1454C3.12983 28.6004 3.55311 25.0354 4.65318 21.654C5.75325 18.2726 7.50857 15.141 9.81891 12.438C12.1292 9.73494 14.9494 7.51344 18.1182 5.90029"
                stroke="#FF708B"
                stroke-width="5.30928"
                stroke-linecap="round"
              />
              <path
                d="M29.6599 2.96378C35.3881 2.8067 41.0185 4.47098 45.7408 7.7171C50.4631 10.9632 54.0338 15.6238 55.939 21.0282"
                stroke="#8676FF"
                stroke-width="5.30928"
                stroke-linecap="round"
              />
              <path
                d="M22.9067 42.6643C25.8457 42.6643 27.8955 40.6688 27.8955 37.9063C27.8955 35.232 26.0357 33.3044 23.5108 33.3044C22.3841 33.3044 21.3864 33.7524 20.8705 34.3632H20.7891L21.1895 30.6777H27.1692V28.5736H19.1058L18.3795 35.7411L20.6533 36.1144C21.1216 35.5985 22.004 35.2524 22.8389 35.2592C24.366 35.266 25.4656 36.3927 25.4588 37.9674C25.4656 39.5217 24.3932 40.628 22.9067 40.628C21.6511 40.628 20.6397 39.8339 20.5515 38.6936H18.108C18.1759 41.0013 20.1918 42.6643 22.9067 42.6643ZM35.4666 42.7389C38.8196 42.7457 40.8219 40.0986 40.8219 35.5375C40.8219 31.0035 38.8061 28.3835 35.4666 28.3835C32.1272 28.3835 30.1182 30.9967 30.1114 35.5375C30.1114 40.0918 32.1137 42.7389 35.4666 42.7389ZM35.4666 40.6145C33.7359 40.6145 32.6499 38.8769 32.6567 35.5375C32.6634 32.2252 33.7426 30.4808 35.4666 30.4808C37.1974 30.4808 38.2766 32.2252 38.2834 35.5375C38.2834 38.8769 37.2042 40.6145 35.4666 40.6145Z"
                fill="#070241"
              />
              <path
                d="M44.413 30.0954C44.413 30.6895 44.4777 31.1352 44.607 31.4322C44.7363 31.7293 44.946 31.8778 45.2361 31.8778C45.8093 31.8778 46.0959 31.2837 46.0959 30.0954C46.0959 28.9141 45.8093 28.3234 45.2361 28.3234C44.946 28.3234 44.7363 28.4702 44.607 28.7638C44.4777 29.0574 44.413 29.5012 44.413 30.0954ZM46.8141 30.0954C46.8141 30.8923 46.6796 31.4951 46.4104 31.9041C46.1448 32.3095 45.7534 32.5122 45.2361 32.5122C44.7468 32.5122 44.3659 32.3042 44.0932 31.8883C43.8241 31.4724 43.6896 30.8748 43.6896 30.0954C43.6896 29.302 43.8189 28.7044 44.0775 28.3025C44.3396 27.9005 44.7258 27.6996 45.2361 27.6996C45.7429 27.6996 46.1326 27.9075 46.4052 28.3234C46.6778 28.7393 46.8141 29.33 46.8141 30.0954ZM49.0265 33.1675C49.0265 33.7652 49.0911 34.2125 49.2204 34.5096C49.3497 34.8032 49.5612 34.95 49.8548 34.95C50.1483 34.95 50.365 34.8049 50.5048 34.5148C50.6446 34.2213 50.7145 33.7721 50.7145 33.1675C50.7145 32.5699 50.6446 32.1277 50.5048 31.8411C50.365 31.5511 50.1483 31.406 49.8548 31.406C49.5612 31.406 49.3497 31.5511 49.2204 31.8411C49.0911 32.1277 49.0265 32.5699 49.0265 33.1675ZM51.4328 33.1675C51.4328 33.9609 51.2982 34.562 51.0291 34.9709C50.7635 35.3764 50.372 35.5791 49.8548 35.5791C49.3585 35.5791 48.9758 35.3711 48.7067 34.9552C48.441 34.5393 48.3082 33.9434 48.3082 33.1675C48.3082 32.3741 48.4375 31.7765 48.6962 31.3746C48.9583 30.9726 49.3445 30.7717 49.8548 30.7717C50.3511 30.7717 50.7373 30.9779 51.0134 31.3903C51.293 31.7992 51.4328 32.3916 51.4328 33.1675ZM50.0802 27.8097L45.8285 35.4742H45.0579L49.3095 27.8097H50.0802Z"
                fill="#070241"
              />
              <path
                d="M25.7059 19.8587H27.7923V24.553H25.7059V19.8587ZM33.0081 17.7723H35.0945V24.553H33.0081V17.7723ZM29.357 14.6428H31.4434V24.553H29.357V14.6428Z"
                fill="#8676FF"
              />
            </svg>
            <div class="title ml-4 text-transpurple100">
              <p class="sector text-base leading-25 ">Public sector cases</p>
              <h1 class="text-21 font-transsemibold">194</h1>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main class="bg-hero h-auto pl-16 pr-107 pb-10">
      <div class="pt-44  grid grid-cols-3 gap-8 pb-44 stat-wrapper">
        <StatBox>
          <template v-slot:header>
            <p class="text-15 leading-22 text-transgray700 pb-28 ">
              Total Cases
            </p>
            <h1 class="text-transblack text-22 font-transsemibold leading-31">
              348
            </h1>
          </template>
          <template v-slot:svg>
            <svg
              width="168"
              height="91"
              viewBox="0 0 168 91"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <rect x="0.75" y="0.5" width="166.5" height="90" fill="white" />
                <rect
                  x="1.875"
                  y="62.375"
                  width="23.625"
                  height="28.125"
                  rx="4.5"
                  fill="#D9E4FF"
                />
                <rect
                  x="30"
                  y="45.5"
                  width="23.625"
                  height="45"
                  rx="4.5"
                  fill="#D9E4FF"
                />
                <rect
                  x="58.125"
                  y="16.25"
                  width="23.625"
                  height="74.25"
                  rx="4.5"
                  fill="#1E5EFF"
                />
                <rect
                  x="86.25"
                  y="35.375"
                  width="23.625"
                  height="55.125"
                  rx="4.5"
                  fill="#D9E4FF"
                />
                <rect
                  x="114.375"
                  y="26.375"
                  width="23.625"
                  height="64.125"
                  rx="4.5"
                  fill="#D9E4FF"
                />
                <rect
                  x="142.5"
                  y="45.5"
                  width="23.625"
                  height="45"
                  rx="4.5"
                  fill="#D9E4FF"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="166.5"
                    height="90"
                    fill="white"
                    transform="translate(0.75 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </template>
        </StatBox>
        <StatBox>
          <template v-slot:header>
            <p class="text-15 leading-22 text-transgray700 pb-28 ">
              Total decided cases
            </p>
            <h1 class="text-transblack text-22 font-transsemibold leading-31">
              200
            </h1>
          </template>
          <template v-slot:svg>
            <svg
              width="167"
              height="91"
              viewBox="0 0 167 91"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <rect x="0.25" y="0.5" width="166.5" height="90" fill="white" />
                <rect
                  x="1.375"
                  y="62.375"
                  width="23.625"
                  height="28.125"
                  rx="4.5"
                  fill="#C4F8E2"
                />
                <rect
                  x="85.75"
                  y="45.5"
                  width="23.625"
                  height="45"
                  rx="4.5"
                  fill="#C4F8E2"
                />
                <rect
                  x="113.875"
                  y="16.25"
                  width="23.625"
                  height="74.25"
                  rx="4.5"
                  fill="#1FD286"
                />
                <rect
                  x="29.4999"
                  y="35.375"
                  width="23.625"
                  height="55.125"
                  rx="4.5"
                  fill="#C4F8E2"
                />
                <rect
                  x="57.625"
                  y="26.375"
                  width="23.625"
                  height="64.125"
                  rx="4.5"
                  fill="#C4F8E2"
                />
                <rect
                  x="142"
                  y="45.5"
                  width="23.625"
                  height="45"
                  rx="4.5"
                  fill="#C4F8E2"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="166.5"
                    height="90"
                    fill="white"
                    transform="translate(0.25 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </template>
        </StatBox>
        <StatBox>
          <template v-slot:header>
            <p class="text-15 leading-22 text-transgray700 pb-28 ">
              Total cases on trial
            </p>
            <h1 class="text-transblack text-22 font-transsemibold leading-31">
              148
            </h1>
          </template>
          <template v-slot:svg>
            <svg
              width="167"
              height="91"
              viewBox="0 0 167 91"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <rect
                  x="0.375"
                  y="0.5"
                  width="166.5"
                  height="90"
                  fill="white"
                />
                <rect
                  x="1.49994"
                  y="62.375"
                  width="23.625"
                  height="28.125"
                  rx="4.5"
                  fill="#FFF4C9"
                />
                <rect
                  x="114"
                  y="45.5"
                  width="23.625"
                  height="45"
                  rx="4.5"
                  fill="#FFF4C9"
                />
                <rect
                  x="85.875"
                  y="16.25"
                  width="23.625"
                  height="74.25"
                  rx="4.5"
                  fill="#FFC700"
                />
                <rect
                  x="142.125"
                  y="35.375"
                  width="23.625"
                  height="55.125"
                  rx="4.5"
                  fill="#FFF4C9"
                />
                <rect
                  x="57.75"
                  y="26.375"
                  width="23.625"
                  height="64.125"
                  rx="4.5"
                  fill="#FFF4C9"
                />
                <rect
                  x="29.6249"
                  y="45.5"
                  width="23.625"
                  height="45"
                  rx="4.5"
                  fill="#FFF4C9"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="166.5"
                    height="90"
                    fill="white"
                    transform="translate(0.375 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </template>
        </StatBox>
      </div>
      <div class="sort-button w-10/12 flexs grid grid-cols-4 gap-5">
        <SortButton v-for="keys in sortKeys" :key="keys.id" class="w-auto">
          <div
            @click="filterQuery = keys.id"
            class="flex justify-center items-center"
          >
            <span>{{ keys.name }}</span>
            <svg
              width="38"
              height="37"
              viewBox="0 0 38 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.9">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.2263 14.5964C9.68124 15.1415 9.63931 15.9992 10.1005 16.5924L10.2263 16.7348L17.7868 24.2953C18.3319 24.8404 19.1896 24.8824 19.7828 24.4211L19.9253 24.2953L27.4858 16.7348C28.0763 16.1443 28.0763 15.1869 27.4858 14.5964C26.9407 14.0513 26.083 14.0094 25.4898 14.4706L25.3473 14.5964L18.8561 21.0865L12.3648 14.5964C11.8197 14.0513 10.962 14.0094 10.3688 14.4706L10.2263 14.5964Z"
                  fill="#7E84A3"
                />
              </g>
            </svg>
          </div>
        </SortButton>
        <!--        <SortButton @click="filterQuery = 'judge'" class="w-auto"-->
        <!--          >Judge</SortButton-->
        <!--        >-->
        <!--        <SortButton @click="filterQuery = 'court'" class="w-">Court</SortButton>-->
        <!--        <SortButton @click="filterQuery = 'date'" class="w-">Date</SortButton>-->
      </div>
      <div
        class="table-section shadow border rounded mt-5 bg-white pt-5 pl-5 pb-10"
      >
        <TableTitle>Lagos Cases</TableTitle>
        <TableSummary class="mt-2"></TableSummary>
        <CasesTable :filtered-data="filteredData" />

        <!--        <section class="pr-5 font-serif w-full mt-8">-->
        <!--          <table class="table-auto w-full">-->
        <!--            <thead>-->
        <!--              <tr-->
        <!--                class="text-left border-b text-sm font-normal text-transgray700 leading-20"-->
        <!--              >-->
        <!--                <th class="w-auto pb-5">Accused Person/Defendent</th>-->
        <!--                <th class="w-auto pb-5">Alleged Offence</th>-->
        <!--                <th class="w-auto pb-5">Presiding Judge</th>-->
        <!--                <th class="w-auto pb-5">Court</th>-->
        <!--                <th class="w-auto  pb-5">Agency</th>-->
        <!--                <th class="w-auto pb-5">Date of sitting</th>-->
        <!--                &lt;!&ndash;                <th v-for="column in columns" :key="column" class="w-auto pb-5">&ndash;&gt;-->
        <!--                &lt;!&ndash;                  {{ column }}&ndash;&gt;-->
        <!--                &lt;!&ndash;                </th>&ndash;&gt;-->
        <!--              </tr>-->
        <!--            </thead>-->
        <!--            <tbody>-->
        <!--              <tr-->
        <!--                v-for="data in filteredData"-->
        <!--                :key="data.key"-->
        <!--                class="border-b text-left text-base text-transpurple"-->
        <!--              >-->
        <!--                <td class="w-auto pt-3 pb-5">{{ data.accused }}</td>-->
        <!--                <td class="w-auto  pt-3 pb-5 flex flex-col">-->
        <!--                  <OffenceTag-->
        <!--                    :class="{-->
        <!--                      'bg-fraud': data.offense === 'Fraud',-->
        <!--                      'bg-bribery': data.offense === 'Bribery',-->
        <!--                      'bg-ml': data.offense === 'Money Laundering'-->
        <!--                    }"-->
        <!--                    class="rounded self-start"-->
        <!--                    >{{ data.offense }}</OffenceTag-->
        <!--                  ><span>{{ data.amount }}</span>-->
        <!--                </td>-->
        <!--                <td class="w-auto pt-3 pb-5">{{ data.presidingJudge }}</td>-->
        <!--                <td class=" pt-3 pb-5 ">-->
        <!--                  {{ data.court }}-->
        <!--                </td>-->
        <!--                <td class=" pt-3 w-10 mr-5 pb-5">{{ data.agency }}</td>-->
        <!--                <td class="w-autos pt-3 pb-5">{{ data.date }}</td>-->
        <!--              </tr>-->
        <!--            </tbody>-->
        <!--          </table>-->
        <!--        </section>-->
      </div>
    </main>
  </section>
</template>

<script>
import StatBox from "@/components/UIElements/StatBox";
import SortButton from "@/components/UIElements/SortButton";
import TableTitle from "@/components/UIElements/TableTitle";
import TableSummary from "@/components/UIElements/TableSummary";

import tableData from "@/data/mockData";
import CasesTable from "@/components/UIElements/CasesTable";
export default {
  name: "ResultPageByStateSearch",
  created() {
    this.gridData = tableData;
  },
  components: {
    CasesTable,
    TableSummary,
    TableTitle,
    SortButton,
    StatBox
  },
  data: () => {
    return {
      filterQuery: "type",
      columns: [
        "Accused Person/Defendent",
        "Alleged Offence",
        "Presiding Judge",
        "Court",
        "Agency",
        "Date of sittings"
      ],
      gridData: [],
      sortKeys: [
        {
          id: "type",
          name: "Crime type"
        },
        {
          id: "presidingJudge",
          name: "Judge"
        },
        {
          id: "court",
          name: "Court"
        },
        {
          id: "date",
          name: "Date"
        }
      ]
    };
  },
  watch: {
    filterQuery: function(newQuery) {
      this.filterQuery = newQuery;
    }
  },
  methods: {
    pressed(key) {
      this.filterQuery = key;
    }
  },
  computed: {
    filteredData: function() {
      let filterKey = this.filterQuery;
      let data = this.gridData;
      if (filterKey) {
        data.sort((a, b) => {
          a = a[filterKey];
          b = b[filterKey];
          return a === b ? 0 : a > b ? 1 : -1;
        });
      }
      return data;
    }
  }
};
</script>

<style scoped lang="scss">
section {
  .stat > .left,
  .right {
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
}
</style>

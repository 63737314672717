<template>
  <div class="font-bold text-transpurple text-lg leading-24">
    <slot>Add Table Title Here</slot>
  </div>
</template>

<script>
export default {
  name: "TableTitle"
};
</script>

<style scoped></style>

<template>
  <div class="flex justify-start">
    <h1
      class="text-sm  flex items-center inline-block  px-3 py-1 rounded-lg leading-20 text-white"
    >
      <slot>Fraud</slot>
    </h1>
  </div>
</template>

<script>
export default {
  name: "OffenceTag"
};
</script>

<style scoped></style>
